<template>
	<div class="sameWidth">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px 20px 0px;">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/police' }">政策法规</el-breadcrumb-item>
			<el-breadcrumb-item>{{form.law_level}}</el-breadcrumb-item>
		</el-breadcrumb>
		<!--
		<table class="common">
			<tr>
				<td>
					<p>
						<span @click="form.law_level = '';search()"
							:style="{color:form.law_level == ''?'#cf1322':'grey',fontWeight:form.law_level == ''?'550':'400'}">全部</span>
						<span @click="form.law_level = '国务院文件';search()"
							:style="{color:form.law_level == '国务院文件'?'#cf1322':'grey',fontWeight:form.law_level == '国务院文件'?'550':'400'}">国务院文件</span>
						<span @click="form.law_level = '财政部规章';search()"
							:style="{color:form.law_level == '财政部规章'?'#cf1322':'grey',fontWeight:form.law_level == '财政部规章'?'550':'400'}">财政部规章</span>
						<span @click="form.law_level = '财政部规范性文件';search()"
							:style="{color:form.law_level == '财政部规范性文件'?'#cf1322':'grey',fontWeight:form.law_level == '财政部规范性文件'?'550':'400'}">财政部规范性文件</span>
						<span @click="form.law_level = '其他部委文件';search()"
							:style="{color:form.law_level == '其他部委文件'?'#cf1322':'grey',fontWeight:form.law_level == '其他部委文件'?'550':'400'}">其他部委文件</span>
						<span @click="form.law_level = '政策解读';search()"
							:style="{color:form.law_level == '政策解读'?'#cf1322':'grey',fontWeight:form.law_level == '政策解读'?'550':'400'}">政策解读</span>
						<span @click="form.law_level = '地方规章办法';search()"
							:style="{color:form.law_level == '地方规章办法'?'#cf1322':'grey',fontWeight:form.law_level == '地方规章办法'?'550':'400'}">地方规章办法</span>
						<span @click="form.law_level = '相关法规';search()"
							:style="{color:form.law_level == '相关法规'?'#cf1322':'grey',fontWeight:form.law_level == '相关法规'?'550':'400'}">相关法规</span>
						<span @click="form.law_level = '国际法规';search()"
							:style="{color:form.law_level == '国际法规'?'#cf1322':'grey',fontWeight:form.law_level == '国际法规'?'550':'400'}">国际法规</span>
					</p>
				</td>
			</tr>
		</table>
		-->
		<el-table :data="tableData" style="width:100%" border size="small">
			<el-table-column prop="title" label="标题" width="590"></el-table-column>
			<el-table-column prop="code" label="发文字号" width="140"></el-table-column>
			<el-table-column prop="issue_date" label="颁布日期" width="90">
				<template slot-scope="scope">
					<span v-if="scope.row.issue_date != '0000-00-00'">{{ scope.row.issue_date}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="implement_date" label="实施日期" width="90">
				<template slot-scope="scope">
					<span v-if="scope.row.implement_date != '0000-00-00'">{{ scope.row.implement_date}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="issue_company" label="颁布单位" width="110">
				<template slot-scope="scope">
					<span v-if="scope.row.issue_company">{{ issue_companys[scope.row.issue_company- 1].name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="danger" style="background-color:#cf1322;" size="mini" @click="goTo(scope)">查看详情
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<p style="margin-top: 15px;">
			<el-pagination @current-change="handleCurrentChange" :current-page="form.page" :page-size="10"
				layout="total,  prev, pager, next, jumper" :total="tableCount" style="margin-top: 10px;">
			</el-pagination>
		</p>

	</div>
</template>
<script>
	export default {
		name: 'policeLevel',
		data() {
			return {
				form: {
					title:'',
					law_level: '',
					issue_dates: [],
					page: 1,
					pageSize:10,
				},
				tableData: [],
				tableCount: 0,
			}
		},
		mounted() {
			this.form.law_level = this.$route.query.law_level;
			this.form.title = this.$route.query.title;
			this.form.issue_dates = [this.$route.query.start_date,this.$route.query.end_date];
			//this.$route.query.str
			this.search();
		},
		methods: {
			search() {
				var that = this;
				this.$axios.post(this.baseUrl + 'index/pdicies/pdiciesList', this.form).then(response => {
					that.tableData = response.data.list;
					that.tableCount = response.data.count;
				}).catch(function(error) {
					window.console.log(error)
				}); //ajax请求失败
			},
			handleCurrentChange(val) {
				this.form.page = val;
				this.search();
			}, //翻页
			goTo(scope) {
				this.$router.push({
					path: '/policeContent',
					query: {
						id: scope.row.id
					}
				})
			},
		}
	}
</script>

<style scoped>
	.area,
	.common {
		font-size: 14px;
		text-align: left;
	}

	.area span,
	.common span {
		margin-right: 10px;
		margin-top: 10px;
		padding: 1px 3px;
	}

	.common span {
		margin-right: 10px;
		cursor: pointer;
	}

	.area td,
	.common td {
		vertical-align: top;
	}

	.tableInfo {
		border-collapse: collapse;
		font-size: 14px;
		width: 100%;
	}

	.tableInfo td,
	.tableInfo th {
		padding: 10px;
		border: #DCDFE6 1px solid;
	}

	.tableInfo th {
		background-color: #F2F6FC;
	}
</style>
